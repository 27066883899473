/**
 * @owner @Appboy/clip
 */

@import "@braze/bootstrap-less/src/variables";
@import "helpers/colors";
@import "helpers/variables";
@import "helpers/mixins";
@import "dashboard/flex-mixins";
@import "parsley";
@import "@braze/bootstrap-less/src/mixins";
@import "@braze/beacon-fonts/Aribau.css";
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/regular";
@import "fontawesome/brands";
@import "fontawesome/v4-shims";

@fa-font-path: "~@vendor/fonts/font-awesome";

@input-top-padding: 10px;

@form-width: 425px;
@form-padding: 50px;

@gray-border: @grey-stardust;
@black-border: @grey-metal;
@link-color: @navy-bay;
@link-hover-color: @navy-cove;

@line-height: 105px;
@line-offset: 330px;
@line-width: 352px;

@activation-login-width: 850px;

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.clear {
  clear: both;
}

html,
body {
  background-color: transparent;
  font-family: @font-aribau;
  margin: 0;
}

html {
  min-height: 100%;
  position: relative;
  background: url("~@images/devise/gradient.svg");
  background-position: bottom -150px center;
  background-size: 1830px auto;
  background-repeat: no-repeat;
}

.login-form {
  width: @form-width;
  // Adding bottom margin here so that the gradient doesn't get in the way of the form:
  margin: @form-padding auto 250px;

  .remember-me {
    margin: 5px 0;
  }

  .unlock-link {
    margin-top: 5px;
    display: block;
  }

  .selected-email {
    font-size: 14px;
  }

  .email-back {
    display: inline-block;
    vertical-align: middle;
  }

  .back-button {
    height: 27px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .clusters-holder {
    margin-top: 40px;
    font-size: 14px;
  }

  .small-link {
    font-size: 12px;
    color: @link-color;

    &:hover {
      color: @link-hover-color;
    }
  }

  .clusters-list {
    font-size: 18px;
    list-style: none;
    padding: 0;
    margin-top: 40px;

    li {
      margin-bottom: 15px;

      a {
        color: @link-color;

        &:hover {
          color: @link-hover-color;
        }
      }
    }
  }

  .forgot-password {
    margin-top: 10px;
  }

  .third-party-auth-buttons {
    .flex-display();
    a {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    .btn {
      border: 2px solid @link-color;
      box-sizing: border-box;
      line-height: 41px;

      &:hover {
        color: @white;
        border-color: @link-hover-color;
        background: @link-hover-color;
      }
    }
    .okta-button {
      background: none;
      color: @link-color;
    }
  }

  .sign-in-banner {
    .company-selected-sign-in {
      vertical-align: middle;
      display: inline-block;
    }
  }

  @media only screen and (max-width: @form-width) {
    width: 100%;
  }

  .container {
    &.short {
      padding-bottom: 25px;
    }

    .contact {
      clear: both;
      font-size: 11px;
      text-align: center;
      font-family: @font-aribau;
    }

    .error-msg {
      font-size: 12px;
      font-weight: bold;
      color: @warning-red;
      margin-top: 5px;
    }

    .remember-device {
      top: -10px;
      left: 80px;
      position: relative;
      font-size: 12px;
      cursor: pointer;
    }

    .verify {
      margin-top: 30px;
    }

    .request-sms {
      font-family: @font-aribau;
      color: @dark-text;
      font-size: 13px;
      width: 650px;
      position: relative;
      bottom: 157px;
      right: 218px;

      input {
        border: none;
        background: none;
        font-size: 13px;
        &:focus {
          outline: 0;
        }
        cursor: pointer;
      }

      &.installation {
        bottom: 106px;
        right: 209px;

        input {
          position: relative;
          left: 162px;
          bottom: 32.5px;
        }
      }
    }

    .footer.installation {
      margin-top: 55px;
    }

    @media only screen and (max-width: @form-width) {
      border: none;
      padding: 25px 20px;

      .oauth-container {
        margin: 10px 0;
      }

      .remember-device {
        top: 0px;
        left: 0px;
      }

      .request-sms {
        bottom: 150px;

        &.installation {
          bottom: 115px;

          input {
            left: -7px;
            bottom: 13px;
          }
        }
      }

      .footer.installation {
        margin-top: 45px;
      }
    }

    .header-bar {
      display: block;
      background: url("https://appboy-images.com/dashboard-assets/dashboard/braze-logo-black.svg") no-repeat;
      background-size: 150px;
      height: 71px;
      width: 150px;
      margin: auto;
      margin-bottom: @form-padding;
    }

    h1,
    .sign-in-banner {
      margin: 0 0 30px 0;
      font-family: @font-aribau;
      font-weight: normal;
      font-size: 20px;
      color: @dark-text;
    }
  }
}

div.control-group {
  margin-bottom: 20px;
  position: relative;
  .clearfix();

  &.password {
    margin-bottom: 4px;
  }

  @media only screen and (max-width: @form-width) {
    margin-bottom: 0px;
  }
}

a {
  text-decoration: none;
}

label {
  display: block;
  font-family: @font-aribau;
  font-weight: 100;
  font-size: 13px;
  color: @dark-text;
  display: block;
  float: left;
  margin: 20px 0;
}

select {
  display: block;
  float: right;
  width: 100%;
  border: none;
  .border-radius(0);
  border-bottom: 1px solid @black-border;
  .no-appearance();
  cursor: pointer;
  background-color: white;
  font-size: 13px;
  font-family: @font-aribau;
  padding: @input-top-padding 3px;
  color: @dark-text;

  &:focus {
    outline: 0;
  }

  @media only screen and (max-width: @form-width) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
  }
}

option.default-value {
  color: green;
}

.select-arrow {
  position: absolute;
  right: 0px;
  top: 5px;
  pointer-events: none;
}

html[data-useragent*="MSIE 10.0"] .select-arrow {
  // IE doesn't have the ability to remove the normal arrow, so remove our custom one instead
  display: none;
}

p.instructions,
ul.instructions {
  margin-bottom: 20px;
  font-family: @font-aribau;
  font-size: 13px;
  color: @dark-text;
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="tel"] {
  border: none;
  border-bottom: 1px solid @black-border;
  .border-radius(0);
  font-family: @font-aribau;
  font-size: 14px;
  display: block;
  width: calc(~"100% - 6px");
  padding: @input-top-padding 3px;
  color: @dark-text;
  .placeholder-color(@grey-french);

  &:focus {
    outline: 0;
  }

  @media only screen and (max-width: @form-width) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
  }
}
.footer {
  text-align: center;
  margin-top: 45px;

  @media only screen and (max-width: @form-width) {
    margin-top: 15px;
  }
}

input.btn,
a.btn {
  width: 100%;
  height: 45px;
  cursor: pointer;
  font-family: @font-aribau;
  font-size: 15px;
  color: @white;

  &.btn-default {
    display: block;
    background-color: @navy-bay;
    line-height: 45px;
    margin-bottom: 10px;

    &:hover {
      background-color: @navy-cove;
    }
  }

  &.continue,
  &.back {
    display: block;
    line-height: 45px;
  }

  &.back {
    background-color: @grey-metal;

    &:hover {
      background-color: lighten(@grey-metal, 5%);
    }
  }

  letter-spacing: 0.05em;
  border: none;

  &.submit {
    background-color: @pink-oyster;
    -webkit-appearance: none;

    &:hover {
      background-color: @pink-apricot;
    }
  }
}

.login-sandbox-form {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - @form-padding + @input-top-padding);
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: @activation-login-width) {
    background-color: @white;
  }
  @media only screen and (min-width: @activation-login-width) {
    flex-direction: row;
  }

  .sandbox-marketing {
    padding: 20px 50px;
    background: url("https://cdn.braze.com/dashboard-assets/dashboard/activation-page-gradient.svg");
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 0%;
    height: 0%;
    display: none;
    flex-direction: column;
    text-align: center;
    position: relative;

    @media only screen and (min-width: @activation-login-width) {
      width: 100%;
      height: 100%;
      min-height: calc(100vh - @form-padding + @input-top-padding);
      display: flex;
    }

    .image-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      max-width: 100%;
    }

    .image-container img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    .download-button {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24%;
      padding: 18px 18px 13px;
      text-align: center;
      font-family: "Sailec", sans-serif;
      font-size: 1.5vw;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: @black;
      border-radius: 127.33px;
      background-color: @white;
      &:hover {
        cursor: pointer;
        background-color: #3accdd;
      }
    }
  }
  .login-form {
    width: @form-width;
    margin: @form-padding auto 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    .header-bar {
      display: block;
    }
    @media only screen and (min-width: @activation-login-width) {
      width: 650px;
      margin: 0px 50px;
      .header-bar {
        display: none;
      }
    }
    h1 {
      font-family: @font-aribau;
      line-height: 30px;
      font-size: 24px;
      text-align: center;
      margin: 48px;
    }
    a {
      color: @blue-viking;
      font-family: @font-aribau;
    }
    .control-group {
      .parsley-errors-list.filled li {
        font-family: @font-aribau;
        color: @beacon-danger-500;
        font-size: 12px;
      }
      .input {
        label {
          font-family: @font-aribau;
          font-size: 13px;
          color: @gray800;
          margin: 0;
        }

        #developer_email {
          border: 1px solid #a8b3b8;
          border-radius: 2px;
          font-family: @font-aribau;
          font-size: 14px;
          background-color: #e9ebed;
          color: @gray900;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 5px;
        }

        #developer_password,
        #developer_password_confirmation {
          border: 1px solid #a8b3b8;
          border-radius: 2px;
          color: #8f9ba2;
          font-family: @font-aribau;
          font-size: 14px;
          font-style: italic;
          padding-left: 5px;

          &:hover {
            border: 1px solid @gray800;
          }
          &:focus {
            border: 2px solid @lightBlue500;
          }

          &.parsley-error {
            border: 2px solid @beacon-danger-500;
            font-family: @font-aribau;
            font-size: 14px;
            color: @gray900;
          }
        }
      }
    }

    p.message {
      margin-bottom: 20px;
      font-family: @font-aribau;
      font-size: 13px;
      color: @dark-text;
    }
    .submit {
      background-color: @lightBlue500;
      border-radius: 2px;
      font-family: @font-aribau;
      font-size: 14px;
      -webkit-appearance: none;
      &:hover {
        background-color: #0dafc5;
      }
      &:active {
        background-color: #006a78;
      }
    }
  }
}

.btn-label {
  font-family: @font-aribau;
  font-size: 12px;
  text-align: center;
  padding-top: 15px;
}

.alert,
.notice {
  font-family: @font-aribau;
  font-size: 12px;

  p {
    margin-bottom: 20px;
  }
}

.alert {
  color: #a82820;
}

.actions a,
.instructions a {
  font-family: @font-aribau;
  font-size: 12px;

  &:hover {
    color: @link-hover-color;
  }
}

.actions {
  margin-top: 8px;
  background-color: @white;
  padding: 11px 45px 20px 45px;
}

.button-description {
  font-family: @font-aribau;
  text-align: center;
}

// This is the id of the div Devise generates if you sign up and have incomplete errors
#error_explanation {
  padding: 10px 0;
  font-family: @font-aribau;

  h2 {
    margin-top: 0;
    color: @orange-coral;
    font-size: 12px;
  }
  ul {
    font-size: 12px;
  }
}

div.oauth-container {
  margin: 20px 0;
  font-size: 11px;
  font-family: @font-aribau;
  text-align: center;
}

div.confirm {
  text-align: center;

  &.email {
    font-family: @font-aribau;
    font-size: 20px;
    margin-bottom: 6px;
    margin-top: 25px;
  }

  &.message-large {
    font-size: 14px;
    padding-bottom: 35px;
  }

  &.message-small {
  }
}

#auth {
  .parsley-errors-list li {
    margin-top: 0;
  }
}

// override the default parsley behavoir
body,
#main #main-col .row {
  input[type="text"].parsley-error,
  input[type="number"].parsley-error,
  input[type="url"].parsley-error,
  input[type="email"].parsley-error,
  input[type="password"].parsley-error,
  textarea.parsley-error {
    border: none;
    border-bottom: 1px solid @warning-red;
  }
}

.link-instructions {
  margin-top: 25px;
  font-family: @font-aribau;
  font-size: 13px;

  a {
    color: @link-color;

    &:hover {
      color: @link-hover-color;
    }
  }
}

.link-instructions {
  a {
    display: block;

    &:nth-child(2) {
      margin-top: 10px;
    }
  }
}

.login-nav {
  margin: 0 auto;
  padding: 0;
  padding-top: @form-padding;
  .flex-display();

  li {
    list-style-type: none;
    border-right: 1px solid @gray-border;
    .flex-grow(1);
    text-align: center;

    &:last-child {
      border-right: none;
    }

    a {
      font-family: @font-aribau;
      font-size: 11px;
      color: @link-color;
      letter-spacing: 0.05em;

      &:hover {
        color: @link-hover-color;
      }
    }
  }
}
